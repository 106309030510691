body {
  color: #333;
  font-family: Roboto, sans-serif !important;
}
a,
.default-link {
  text-decoration: underline;
  color: #0096FB;
  cursor: pointer;
}
a.green-link,
.default-link.green-link {
  color: #4CAF50;
}
h1 {
  border-bottom: 2px solid lightgray;
}
h3 {
  font-weight: normal;
}
label {
  line-height: 20px;
  font-size: 15px;
  font-weight: bold;
}
input[type="text"] {
  padding: 0 10px;
  height: 30px;
  width: 50%;
  border: 1px solid #d3d3d3;
  border-radius: 5px;
  max-width: 100%;
}
input[type="radio"],
input[type="checkbox"] {
  height: 20px;
  width: 20px;
  min-width: 20px;
  margin-right: 15px;
  align-self: center;
  float: left;
  background: #F9FFFF;
  line-height: 16px;
}
.error-block {
  font-size: 12px;
}
.error,
.error-block {
  color: #FF1919;
}
.notify-block {
  background: #FCF8E3;
  border: 2px solid #FAEBCC;
  border-radius: 10px;
  color: #8A6D3B;
  padding: 20px 30px;
  text-align: left;
}
.notify-block.blue {
  background: #D9EDF7;
  color: #337190;
  border-color: #BCE8F1;
}
.box-content {
  display: flex;
  flex-direction: column;
  max-width: 800px;
  background: #F9FFFF;
  margin: 0 auto;
  padding: 25px 50px;
  border: 2px solid #7aa54b;
}
.box-content .row {
  padding: 0 0 20px;
  text-align: center;
}
.box-content .row label {
  font-weight: normal;
}
.box-content .row .input-wrap {
  height: 50px;
}
.box-content .row .input-wrap.contact-value {
  margin-top: 10px;
}
.box-content .row .action-type {
  width: 54%;
  margin: 0 auto;
}
.box-content .row .action-type label {
  text-align: left;
  line-height: 27px;
  cursor: pointer;
}
.box-content .greeting {
  overflow: hidden;
}
.box-content .greeting span {
  float: left;
  font-weight: normal;
}
.box-content .greeting .unsubscription-step {
  float: right;
  font-size: 20px;
  line-height: 40px;
}
.ui-lang-select {
  width: 110px;
  height: 30px;
  border: none;
  float: right;
  margin-bottom: 10px;
  border: 1px solid #d9dce3;
}
.ui-lang-select .Select-multi-value-wrapper {
  height: 30px;
}
.ui-lang-select .Select-menu-outer,
.ui-lang-select .Select-menu {
  z-index: 9999;
  background: white;
  position: relative;
}
.ui-lang-select .Select-option {
  box-sizing: border-box;
  cursor: pointer;
  display: block;
  height: 30px;
  line-height: 27px;
}
.ui-lang-select .Select-option.is-focused {
  background-color: lightgrey;
}
.ui-lang-select .Select-option.is-disabled {
  cursor: default;
}
.ui-lang-select .ui-lang-option {
  overflow: hidden;
  padding: 1px 4px;
}
.ui-lang-select .ui-lang-option .label {
  float: left;
  margin-left: 5px;
  cursor: pointer;
  line-height: 28px;
}
.ui-lang-select .ui-lang-option .ico {
  width: 16px;
  height: 16px;
  margin-top: 5px;
  float: left;
  cursor: pointer;
  background-size: contain!important;
}
.ui-lang-select .ui-lang-option .ico[data-tag="en"] {
  background: url("../static/gb.png");
}
.ui-lang-select .ui-lang-option .ico[data-tag="cs"] {
  background: url("../static/cz.png");
}
.ui-lang-select .ui-lang-option .ico[data-tag="pl"] {
  background: url("../static/pl.png");
}
.ui-lang-select .ui-lang-option .ico[data-tag="es"] {
  background: url("../static/es.png");
}
button.volsor-btn {
  padding: 5px 10px;
  border-style: solid;
  color: white;
  background: #616161;
  border-color: #616161;
  border-radius: 5px;
  cursor: pointer;
}
button.volsor-btn.btn-blue {
  color: #0096FB;
  border-color: #0096FB;
  background: none;
}
button.volsor-btn.btn-blue.filled {
  border-color: #0096FB;
  background: #0096FB;
  color: white;
}
button.volsor-btn.big-one {
  padding: 10px 20px;
  font-size: 16px;
}
@media (max-width: 400px) {
  .action-type {
    width: 100%!important;
    margin: 0 -15px;
  }
  input[type="text"] {
    width: 85%;
  }
  .box-content {
    padding: 25px 20px;
  }
}
@media (max-width: 800px) {
  input[type="text"] {
    height: 45px;
    margin: 5px;
    font-size: 16px;
  }
}
.__react_component_tooltip {
  pointer-events: auto !important;
}
.Select-menu-outer {
  border: 1px solid #d9dce3;
  margin-top: 10px;
}
.Select-menu-outer:before {
  content: '';
  position: absolute;
  top: -8px;
  left: 23px;
  width: 14px;
  height: 15px;
  border: 1px solid #d9dce3;
  border-width: 1px 0px 0px 1px;
  transform: rotate(45deg);
  background: inherit;
}
.is-open > .Select-control .Select-arrow {
  top: -5px;
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}
.Select {
  position: relative;
}
.Select.is-disabled > .Select-control {
  background-color: #f9f9f9;
}
.Select.is-disabled .Select-arrow-zone {
  cursor: default;
  pointer-events: none;
  opacity: 0.35;
}
.Select-control {
  cursor: default;
  display: table;
  border-spacing: 0;
  border-collapse: separate;
  height: 36px;
  outline: none;
  overflow: hidden;
  position: relative;
  width: 100%;
}
.Select-control .Select-input:focus {
  outline: none;
}
.is-searchable.is-open > .Select-control {
  cursor: text;
}
.is-open > .Select-control .Select-arrow {
  top: -5px;
  border-color: transparent transparent #999;
  border-width: 0 5px 5px;
}
.Select-placeholder,
.Select--single > .Select-control .Select-value {
  bottom: 0;
  color: #aaa;
  left: 0;
  line-height: 34px;
  padding-left: 10px;
  padding-right: 10px;
  position: absolute;
  right: 0;
  top: 0;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.has-value.Select--single > .Select-control .Select-value .Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value .Select-value-label {
  color: #333;
}
.has-value.Select--single > .Select-control .Select-value a.Select-value-label,
.has-value.is-pseudo-focused.Select--single > .Select-control .Select-value a.Select-value-label {
  cursor: pointer;
  text-decoration: none;
}
.Select--multi .Select-clear-zone {
  width: 17px;
}
.Select-arrow-zone {
  cursor: pointer;
  display: table-cell;
  position: relative;
  text-align: center;
  vertical-align: middle;
  width: 15px;
  padding-right: 5px;
}
.Select-arrow {
  border-color: #999 transparent transparent;
  border-style: solid;
  border-width: 5px 5px 2.5px;
  display: inline-block;
  height: 3px;
  width: 0;
  position: relative;
}
.Select--multi .Select-multi-value-wrapper {
  display: inline-block;
}
